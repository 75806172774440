import React, { useEffect, useState } from 'react';
import Sidebar from '../Navigation/SideBar';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { Backdrop, Box, Fade, FormControlLabel, Grid, Modal, Button, CircularProgress } from '@mui/material';
import { iparhaiServer } from '../../store/services/config/api.config';

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

const TwoFactorAuthentication = () => {
    const userId = localStorage.getItem('userId');
    const [switchStatus, setSwitchStatus] = useState(false);
    const [qrCodeSrc, setQrCodeSrc] = useState("");
    const [backupCodes, setBackupCodes] = useState([]);
    const [twoFactorSecret, setTwoFactorSecret] = useState(""); // State for storing the twoFactorSecret
    const [qrcodeModal, setQrcodeModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetch2FAStatus();
    }, [userId]);

    const fetch2FAStatus = async () => {
        try {
            const res = await iparhaiServer.get(`/api/userAuth/get2FAStatus/${userId}`);
            const { twoFA, twoFactorSecret, backupCodes } = res.data;
            setSwitchStatus(twoFA);
            setTwoFactorSecret(twoFactorSecret || "");
            setBackupCodes(backupCodes || []);
            console.log(backupCodes, "backupCodes");
        } catch (error) {
            console.log("Error fetching 2FA status:", error);
        }
    };
    


    const getQRCODE = async () => {
        setLoading(true);
        try {
            const response = await iparhaiServer.get(`/api/userAuth/getQRCode/${userId}`);
            console.log(response)
            setQrCodeSrc(response.data.qrCode);
        } catch (error) {
            console.error("Error fetching QR code:", error.response);
        } finally {
            setLoading(false);
        }
    };

    const handleSwitchChange = async (event) => {
        const newStatus = event.target.checked;
        setSwitchStatus(newStatus);
        try {
            await iparhaiServer.post(`/api/userAuth/set2FAStatus/${userId}`, { status: newStatus });
            fetch2FAStatus();
        } catch (error) {
            console.log(error);
        }
    };

    const handleOpenModal = () => {
        getQRCODE();
        setQrcodeModal(true);
    };

    const handleCloseModal = () => setQrcodeModal(false);

    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={0} sx={{ zIndex: 100 }} sm={3}>
                    <Sidebar />
                </Grid>
                <Grid className='px-4 py-4' item xs={12} sm={9}>
                    <h2 className='text-4xl font-medium'>Two Factor Authentication</h2>

                    <FormControlLabel
                        control={<Android12Switch />}
                        label="Enable two factor authentication"
                        checked={switchStatus}
                        onChange={handleSwitchChange}
                    />

                    {switchStatus && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenModal}
                            sx={{ mt: 2 }}
                        >
                            Scan QR Code
                        </Button>
                    )}

                    <div className="my-10">
                        <h3 className='text-2xl font-medium'>Backup Codes</h3>
                        {switchStatus ? (
                            <ul>
                                {backupCodes && backupCodes.map((code, index) => (
                                    <li key={index} className="text-md text-gray-700">{code}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>Enable two-factor authentication to see backup codes.</p>
                        )}
                    </div>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={qrcodeModal}
                        onClose={handleCloseModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={qrcodeModal}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: 400,
                                    bgcolor: "background.paper",
                                    boxShadow: 24,
                                    borderRadius: 10,
                                    p: 4,
                                }}
                            >
                                {loading ? (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                        <CircularProgress />
                                    </Box>
                                ) : qrCodeSrc ? (
                                    <div className="w-[320px] grid place-items-center bg-slate-100 shadow-xl rounded-xl overflow-hidden p-4">
                                        <img
                                            className="rounded bg-green-300 p-4"
                                            style={{ backgroundColor: "#004e8f", color: "white" }}
                                            src={qrCodeSrc}
                                            alt="QR Code"
                                        />
                                        <h1 className="text-darkNavy font-bold text-2xl text-center mt-5">
                                            Scan the QR code to set up 2FA
                                        </h1>
                                        <p className="text-grey text-md text-center py-3">
                                            Scan the QR code with your authenticator app to complete the setup.
                                        </p>
                                    </div>
                                ) : (
                                    <p>No QR code available.</p>
                                )}
                            </Box>
                        </Fade>
                    </Modal>
                </Grid>
            </Grid>
        </>
    );
};

export default TwoFactorAuthentication;
