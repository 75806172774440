
  
  import React, { useState, useEffect } from 'react';
  import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
  import authService from '../../store/services/auth.service';
  
  const SubadminsTable = () => {
    const [subadmins, setSubadmins] = useState([]);
  
    const handleDelete = async (id) => {
      // try {
      //   await authService.deleteSubadmin(id); // Assuming you have a deleteSubadmin method in your authService
      //   setSubadmins(subadmins.filter(subadmin => subadmin.id !== id));
      // } catch (error) {
      //   console.error('Failed to delete subadmin', error);
      // }
    };
  
    useEffect(() => {
      const fetchSubadmins = async () => {
        try {
          const companyId = localStorage.getItem("cid");
          // console.log("companyId:", companyId); // Ensure companyId is logged correctly
          const response = await authService.getsubadmins({companyId});
          setSubadmins(response.data);
        } catch (error) {
          console.error('Failed to fetch subadmins', error);
        }
      };
    
      fetchSubadmins();
    }, []);
    
    
  
    return (
      <TableContainer component={Paper}>
        <h2 style={{ textAlign: 'center' }}>Subadmins Details</h2>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subadmins.map((subadmin) => (
              <TableRow key={subadmin.id}>
                <TableCell>{subadmin.name}</TableCell>
                <TableCell>{subadmin.email}</TableCell>
                <TableCell>
                  <Button variant="contained" color="secondary" onClick={() => handleDelete(subadmin.id)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  export default SubadminsTable;
  
  
  
  