import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import ClearIcon from "@mui/icons-material/Clear";
import MuiAlert from "@mui/material/Alert";
import { Select, MenuItem, Menu } from "@mui/material"; // Import Select and MenuItem
import { readFromExcel } from "../utils/readfromexcel";
import testService from "../store/services/test.service";
import { downloadFile } from "../utils/micsfun";
import Alert from "@mui/material/Alert";
import * as XLSX from "xlsx";
// import { FormControlLabel, FormControl, InputLabel, Checkbox, } from "@mui/material";
import {Checkbox, ListItemText, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import Questionform from "./Test/textbasedtest/questionform";
import { MCQtest } from "./Test/testcreation/mcqtest";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,

} from "@mui/material";
import Creationform from "./Test/testcreation/creationform";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jwtDecode from "jwt-decode";
import authService from "../store/services/auth.service";
const Createassesment = ({ test, availableMinutes }) => {
  const cid = localStorage.getItem("cid");
  const token = localStorage.getItem('token')
  const [activeTab, setActiveTab] = useState(0);
  const [questionType, setQuestionType] = useState("");
  const [gradelevel, setgradelevel] = useState("");
  const [enableAiVigilance, setEnableAiVigilance] = useState(false);
  const [enablecodegrader, Setenablecodegrader] = useState(false);
  const [assessmentName, setAssessmentName] = useState(test ? test.name : "");
  const [emailInput, setEmailInput] = useState("");
  const [inviteMethod, setInviteMethod] = useState(0);
  const [submitloading, setSubmitLoading] = useState(false)
  const [emails, setEmails] = useState([]);
  const [ips, setIps] = useState([])
  const [ip, setIp] = useState('')
  const [showInviteTab, setShowinviteTab] = useState(false);
  const [timezone , setTimezone] = useState('')
  const [enableNegativeMarking, setEnableNegativeMarking] = useState(false);
  const [marksToBeDeducted, setMarksToBeDeducted] = useState();
  const [assessmentDescription, setAssessmentDescription] = useState(
    test ? test.description : ""
  );
  const [assessmentDuration, setAssessmentDuration] = useState(
    test ? test.duration : ""
  );
  const [startDate, setStartDate] = useState(test ? test.startdate : "");
  const [endDate, setEndDate] = useState(test ? test.expiredate : "");
  const [imageUrl, setImageUrl] = useState('')
  const [questionData, setQuestionData] = useState(test ? test.questions : []);
  const [textquestionData, settextQuestionData] = useState(
    test ? test.questions : []
  );
  const [error, setError] = useState([false, ""]);

  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const [subAdmins, setSubAdmins] = useState([]);
  const [selectedSubAdmins, setSelectedSubAdmins] = useState([]);
 const companyId=localStorage.getItem("cid")
  useEffect(() => {
    const fetchSubAdmins = async () => {
      try {
        const data = await authService.getsubadmins(companyId);
        
        setSubAdmins(data.data);
      } catch (err) {
        console.error("Failed to fetch sub-admins", err);
      }
    };

    fetchSubAdmins();
  }, [companyId]);

  

  useEffect(() => {
    if (emails.length > jwtDecode(token).testLimit) {
      setError([true, "Invite limit exceeded"])
    }
  }, [emails, questionData, imageUrl, excelData])

  const handleFileChange = async (e, singleQuestions) => {
    const file = e.target.files && e.target.files[0] ? e.target.files[0] : null;
    const fileType = file && file.type

    if (file) {
      if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileType === 'application/vnd.ms-excel') {

        // Handle Excel file
        setQuestionData([]);
        const fetchedData = await readFromExcel(file);
        if (fetchedData.length > 0) {
          const allKeys = Array.from(new Set(fetchedData.flatMap((obj) => Object.keys(obj))));
          const updatedData = fetchedData.map((obj) => {
            const newObj = {};
            allKeys.forEach((key) => {
              newObj[key] = obj[key] ?? '';
            });
            return newObj;
          });
          setQuestionData([...updatedData]);
        } else {
          setError([true, 'File cannot be empty']);
          return;
        }

        const reader = new FileReader();

        reader.onload = (event) => {
          const binaryString = event.target.result;
          const workbook = XLSX.read(binaryString, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(sheet, { header: 1, defval: '' });

          const headers = ['question', 'option1', 'option 2', 'option 3', 'option 4', 'image', 'reason', 'answer', 'marks', 'action'];
          data[0] = headers;

          for (let i = 1; i < data.length; i++) {
            data[i].splice(5, 0, "")
            data[i].push('')
          }

          setExcelData(data);
        };

        reader.readAsBinaryString(file);

      }
    } else {
      const formattedSingleQuestions = [
        singleQuestions.question,
        { text: singleQuestions.option1, image: singleQuestions.optionImage1 },
        { text: singleQuestions.option2, image: singleQuestions.optionImage2 },
        { text: singleQuestions.option3, image: singleQuestions.optionImage3 },
        { text: singleQuestions.option4, image: singleQuestions.optionImage4 },
        singleQuestions.questionImage,
        '',
        singleQuestions.answer,
        singleQuestions.marks,
        ''
      ];
      setQuestionData([...questionData, singleQuestions]);

      if (excelData.length === 0) {
        setExcelData([['question', 'option 1', 'option 2', 'option 3', 'option 4', 'image', 'reason', 'answer', 'marks', 'action'], formattedSingleQuestions]);
        setImageUrl('')
        return;
      }
      if (singleQuestions.image && excelData[0].length !== 9) {
        excelData[0] = ['question', 'option 1', 'option 2', 'option 3', 'option 4', 'image', 'reason', 'answer', 'marks', 'action']
        for (let i = 1; i < excelData.length; i++) {
          excelData[i].splice(6, 0, '');
        }
      }

      setExcelData([...excelData, formattedSingleQuestions]);

      setImageUrl('')
      return;
    }
    return
  };

  const handleNext = () => {
    if (activeTab === 0 && validateAssessmentInfo()) {
      if (assessmentDuration > availableMinutes) {
        setError([true, "You don't have enough test minutes"])
        return
      }
      if (questionType === "MCQ-based" && excelData.length !== 0 && excelData[0].length < 7) {
        setQuestionData([])
        setExcelData([])
      }
      if (questionType === "Text-based" && excelData.length !== 0 && excelData[0].length > 6) {
        setQuestionData([])
        setExcelData([])
      }

      setActiveTab(1);
      return
    } else if (activeTab === 1) {
      if (questionType === "MCQ-based") {
        if (questionData.length > 0) {
          setActiveTab(2); // Proceed to the next tab if MCQ-based questions are available
        } else {
          setError([true, "Please upload MCQ-based questions."]);
        }
      } else if (questionType === "Text-based") {
        if (textquestionData.length > 0) {
          setActiveTab(2); // Proceed to the next tab if text-based questions are available
        } else {
          setError([true, "Please add text-based questions."]);
        }
      }
    } else if (activeTab === 2 && inviteMethod === 2) {
      setShowinviteTab(true);
      setActiveTab(3);
    }
    else if (activeTab === 2 && inviteMethod === 1) {
      setActiveTab(3);
      setShowinviteTab(false)
      setEmails([])
    }
    else if (activeTab === 2 && inviteMethod === 0) {
      setError([true, "Please choose any option"])
    }
    else if (showInviteTab && activeTab === 3 && emails.length > 0) {
      setActiveTab(4);
      // setShowinviteTab(true)
      // setEmails([])
    }
    else if (showInviteTab && activeTab === 3 && emails.length === 0) {
      setError([true, "Enter emails to send invite"])
    }
    else if (!showInviteTab && activeTab === 3) {
      setActiveTab(5)
    }
    else if (showInviteTab && activeTab === 4) {
      setActiveTab(5)
    }

  };

  const handlePrevious = () => {
    if (activeTab === 1) {
      setActiveTab(0); // Go back to the "Assessment Info" tab
    } else if (activeTab === 2) {
      setActiveTab(1); // Go back to the "Upload Assessment" tab
    } else if (activeTab === 3) {
      setShowinviteTab(false);
      setInviteMethod(0);
      setActiveTab(2); // Go back to the "Upload Assessment" tab
    } else if (activeTab === 4) {
      if (inviteMethod === 2) {
        setActiveTab(3); // Go back to the "Upload Assessment" tab
      } else {
        setActiveTab(2);
      }
    } else if (showInviteTab && activeTab === 5) {
      setActiveTab(4)
    }
    else {
      setActiveTab(3)
    }
  };

  const handleQuestionDataChange = (singleQuestions) => {
    const formattedSingleQuestions = [
      singleQuestions.question,
      singleQuestions.marks,
      singleQuestions.image,
      singleQuestions.type,
      ''
    ];
    // console.log(singleQuestions)
    // console.log(textquestionData)
    settextQuestionData([...textquestionData, singleQuestions]);
    if (excelData.length === 0) {
      setExcelData([['question', 'marks', 'image', 'type', 'action'], formattedSingleQuestions]);
      setImageUrl('')
      return;
    }
    setExcelData([...excelData, formattedSingleQuestions])
  };
 const createdBy=localStorage.getItem("userId")
  const handleSubmit = () => {
    const {
      id,
      companyId,
      duration,
      name,
      questions,
      description,
      QuestionType,
      startdate,
      expiredate,
    } = {
      id: test ? test.id : "",
      companyId: cid,
      duration: test ? test.duration : assessmentDuration,
      name: assessmentName,
      questions: questionType === "MCQ-based" ? questionData : textquestionData,
      description: assessmentDescription,
      QuestionType: questionType,
      startdate: startDate,
      expiredate: endDate,
    };
    if (test) {
      setSubmitLoading(true)
      testService
        .editTest(
          id,
          duration,
          name,
          questions,
          description,
          startdate,
          expiredate
        )
        .then((res) => {
          setError([false, ""]);
          setSuccessMessage("Test updated successfully");
          setSubmitLoading(false)
          setSnackbarOpen(true);
          window.location.reload();
        })
        .catch((err) => {
          setSubmitLoading(false)
          setError([true, "Error updating test"]);
        });
    } else {
      // console.log(emails);
      setSubmitLoading(true)
      testService
        .createTest(
          companyId,
          duration,
          name,
          questions,
          description,
          QuestionType,
          startdate,
          expiredate,
          enableAiVigilance,
          emails,
          ips,
          inviteMethod,
          gradelevel,
          enableNegativeMarking,
          marksToBeDeducted,
          createdBy,
          timezone
        )
        .then((res) => {
          // console.log(res);
          setError([false, ""]);
          setSuccessMessage("Test created successfully");
          setSubmitLoading(false)
          setSnackbarOpen(true);
          window.location.reload();
        })
        .catch((err) => {
          setError([true, "Error creating test"]);
          setSubmitLoading(false)
        });
    }
  };

  const validateAssessmentInfo = () => {
    const { duration, name, description, startdate, expiredate } = {
      duration: assessmentDuration,
      name: assessmentName,
      description: assessmentDescription,
      startdate: startDate,
      expiredate: endDate,
    };

    // Ensure questionType is properly initialized
    const selectedQuestionType = questionType; // Use a separate variable to avoid confusion
    const selectedgradelevel = gradelevel
    // Validation checks
    if (name === "") {
      setError([true, "Name is required"]);
      return false;
    } else if (description === "") {
      setError([true, "Description is required"]);
      return false;
    } else if (duration === "") {
      setError([true, "Duration is required"]);
      return false;
    } else if (startdate === "") {
      setError([true, "Start date is required"]);
      return false;
    } else if (expiredate === "") {
      setError([true, "Expire date is required"]);
      return false;
    } else if (new Date(startdate) > new Date(expiredate)) {
      setError([true, "Start date cannot be greater than end date"]);
      return false;
    } else if (selectedQuestionType === "") {
      setError([true, "Assessment type is required"]); // Validate that an assessment type is selected
      return false;
    }
    else if (selectedQuestionType ==="Text-based" && gradelevel === "") {
      setError([true, "Grade level is required"]); // Validate that an assessment type is selected
      return false;
    }
    else {
      setError([false, ""]);
      return true;
    }
  };

  const fieldStyle = {
    marginBottom: "16px",
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };


  const handleAddInviteLinks = () => {
    const maxEmails = availableMinutes / assessmentDuration; // Calculate the maximum number of emails based on the total time and test duration

    if (validateEmail(emailInput)) {
      if (emails.includes(emailInput)) {
        setError([true, "Email already exists"]);
        return;
      }
      if (emails.length >= Math.floor(maxEmails)) {
        setError([true, `You can only add ${Math.floor(maxEmails)} emails`]);
        return;
      }
      setEmails([...emails, emailInput]);
      setEmailInput("");
    } else {
      setError([true, "Invalid Email"]);
    }
  };


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    const value = parseInt(event.target.value, 10); // Convert string to number
    setInviteMethod(value);
  };
  const handleadminChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSubAdmins(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleRemoveEmail = (index) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  const handleRemoveIp = (index) => {
    setIps(ips.filter((_, i) => i !== index));
  };

  const validateEmail = (email) => {
    const atSymbolIndex = email.indexOf('@');
    return (
      atSymbolIndex > 0 &&
      atSymbolIndex < email.length - 1 &&
      email.indexOf('@', atSymbolIndex + 1) === -1
    );
  };

  const handleEmailFile = async (e) => {
    setEmails([]);
    const file = e.target.files[0];
    const fetchedData = await readFromExcel(file);
    // console.log(fetchedData);

    // Extract emails from the fetched data
    const emails = fetchedData.map((item) => item.emails).flat();
    const maxEmails = availableMinutes / assessmentDuration;

    if (emails.length >= Math.floor(maxEmails)) {
      setError([true, "You don't have enough test minutes"]);
      return;
    }

    // Check for duplicates
    const uniqueEmails = emails.filter((email, index) => emails.indexOf(email) === index);

    // Validate each email
    const invalidEmails = uniqueEmails.filter(email => !validateEmail(email));
    if (invalidEmails.length > 0) {
      setError([true, `Invalid email(s) found: ${invalidEmails.join(", ")}`]);
      return;
    }

    // Check the number of emails to prevent exceeding the limit
    const { testLimit } = jwtDecode(token);
    if (uniqueEmails.length > testLimit) {
      setError([true, "Invite limit exceeded"]);
      return;
    }

    // Use a functional update to ensure you're working with the latest state
    setEmails((prevLinks) => [...prevLinks, ...uniqueEmails]);
    setAnchorEl(null);
  };

  const handleAddIP = () => {
    // Regular expression pattern to validate IP address (IPv4) with at least three dots
    const ipPattern = /^(\d{1,3}\.){3}\d{1,3}$/;

    // Trim whitespace from the entered IP and check if it matches the pattern
    if (ip.trim() === '' || !ipPattern.test(ip.trim())) {
      // Invalid IP address, handle error or alert
      setError([true, 'Invalid IP address. Please enter a valid IPv4 address with at least three dots.']);
      return;
    }

    // IP is valid, add it to the ips state
    setIps([...ips, ip.trim()]);
    setIp('');
  };


  return (
    <div >
      {error[0] &&
        (setTimeout(() => {
          setError([false, ""]);
        }, 2000),
          (
            <Alert
              sx={{
                width: "fit-content",
              }}
              severity="error"
            >
              {error[1]}
            </Alert>
          ))}
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => {
          console.log(newValue)
          if (questionType === "MCQ-based" && newValue === 2 && questionData.length === 0) {
            setError([true, 'Please add MCQ-based questions.'])
            return
          }
          if (questionType === "Text-based" && newValue === 2 && textquestionData.length === 0) {
            setError([true, 'Please add text-based questions.'])
            return
          }
          else if (newValue === 3 && !inviteMethod) {
            console.log('hello')
            setError([true, "Please choose any option"])
            return
          }
          if (newValue) {
            if (validateAssessmentInfo()) {
              if (assessmentDuration > jwtDecode(token).availableMinutes) {
                setError([true, "You dot have enough minutes"])
                return
              }
              setActiveTab(newValue);
            }
          } else {
            setActiveTab(newValue);
          }
        }}
      >
        <Tab label="Assessment Info" />
        <Tab label="Upload Assessment" />
        <Tab label="Invite Method" />
        {showInviteTab && <Tab label="Send Invite Link" />}
        <Tab label="IPs(optional)" />
      </Tabs>
      {activeTab === 0 && (
        <Creationform
          assessmentName={assessmentName}
          setAssessmentName={setAssessmentName}
          assessmentDescription={assessmentDescription}
          setAssessmentDescription={setAssessmentDescription}
          questionType={questionType}
          setQuestionType={setQuestionType}
          gradelevel={gradelevel}
          setgradelevel={setgradelevel}
          assessmentDuration={assessmentDuration}
          setAssessmentDuration={setAssessmentDuration}
          startDate={startDate}
          setStartDate={setStartDate}
          enableNegativeMarking={enableNegativeMarking}
          setEnableNegativeMarking={setEnableNegativeMarking}
          marksToBeDeducted={marksToBeDeducted}
          setMarksToBeDeducted={setMarksToBeDeducted}
          endDate={endDate}
          setEndDate={setEndDate}
          enableAiVigilance={enableAiVigilance}
          enablecodegrader={enablecodegrader}
          setEnableAiVigilance={setEnableAiVigilance}
          handleNext={handleNext}
          error={error}
          setError={setError}
          fieldStyle={fieldStyle}
          setTimezone={setTimezone}
          timezone={timezone}
        />
      )}

      {activeTab === 1 ? (
        questionType === "MCQ-based" ? (
          <>
            <MCQtest
              questionData={questionData}
              excelData={excelData}
              handleFileChange={handleFileChange}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              error={error}
              setError={setError}
              setQuestionData={setQuestionData}
              setExcelData={setExcelData}
              imageUrl={imageUrl}
              downloadFile={downloadFile}
            />
          </>
        ) : questionType === "Text-based" ? (
          <>
            <Questionform excelData={excelData} settextQuestionData={settextQuestionData} setExcelData={setExcelData} textquestionData={textquestionData} setError={setError} onQuestionsChange={handleQuestionDataChange} />
            <Button onClick={handlePrevious}>Previous</Button>
            
            <Button variant="contained" onClick={handleNext}>
              Next
            </Button>
          </>
        ) : null
      ) : null}

      {activeTab === 2 ? (
        <>
          <FormControl component="fieldset">
            <FormLabel component="legend">Choose Option</FormLabel>
            <RadioGroup
              aria-label="option"
              name="option"
              // You can use state to manage the selected value
              value={inviteMethod}
              onChange={handleChange}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Invite through a Code"
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="Invite through a Email"
              />
            </RadioGroup>
          </FormControl>
          <div className="my-4 flex gap-4">
            <Button onClick={handlePrevious}>Previous</Button>
            <Button variant="contained" onClick={handleNext}>
              Next
            </Button>
          </div>
        </>
      ) : null}

      {showInviteTab && activeTab === 3 ? (
        <>
          {/* <Questionform onQuestionsChange={handleQuestionDataChange} /> */}
          <div className="my-10 flex gap-4 items-center">
            <TextField
              type="email"
              sx={{ width: 250 }}
              value={emailInput}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleAddInviteLinks();
                }
              }}
              onChange={(e) => {
                setEmailInput(e.target.value);
              }}
              id="outlined-basic"
              label="Enter Email"
              variant="outlined"
            />
            <Button variant="contained" onClick={handleAddInviteLinks}>
              ADD
            </Button>
            <span>OR</span>
            <Button
              variant="outlined"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              ADD BULK
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => {
                downloadFile("emailtemplate.xlsx")
                setAnchorEl(null)
              }}>Download Template</MenuItem>
              <MenuItem>
                <label htmlFor="fileInput" className="w-full">
                  <Button
                    className="w-full"
                    variant="contained"
                    component="span"
                    color={questionData.length > 0 ? "error" : "primary"}
                  >
                    {emailInput.length > 0
                      ? "Change File"
                      : "Upload Excel File"}
                  </Button>
                </label>
                <input
                  accept=".xlsx, .xls, csv"
                  style={{ display: "none" }}
                  id="fileInput"
                  type="file"
                  onClick={(event) => (event.target.value = null)}
                  onChange={handleEmailFile}
                />
              </MenuItem>
            </Menu>
          </div>
          {emails.length > 0 && (
            <>
              <div className="w-full bg-slate-100 flex gap-2 flex-wrap rounded-lg p-2">
                {emails.map((e, id) => {
                  return (
                    <span
                      className="bg-[#b0c8dc] rounded-xl text-sm font-medium px-2"
                      key={id}
                    >
                      {e}{" "}
                      <button onClick={() => handleRemoveEmail(id)}>
                        <ClearIcon color="error" className="cursor-pointer" />
                      </button>{" "}
                    </span>
                  );
                })}
              </div>
            </>
          )}

          <div className="my-4 flex gap-4">
            <Button onClick={handlePrevious}>Previous</Button>
            <Button variant="contained" onClick={handleNext}>
              Next
            </Button>
          </div>
        </>
      ) : null}

      {!showInviteTab && activeTab === 3 && (
        <div>
          <Typography variant="h6">Do you want to add allowed network?</Typography>

          <div className="my-10 flex gap-4 items-center">
            <TextField
              type="email"
              sx={{ width: 250 }}
              value={ip}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleAddIP();
                }
              }}
              onChange={(e) => {
                setIp(e.target.value);
              }}
              id="outlined-basic"
              label="Enter IP address"
              variant="outlined"
            />
            <Button variant="contained" onClick={handleAddIP}>
              ADD
            </Button>
          </div>
          {ips.length > 0 && (
            <>
              <div className="w-full bg-slate-100 flex gap-2 flex-wrap rounded-lg p-2">
                {ips.map((e, id) => {
                  return (
                    <span
                      className="bg-[#b0c8dc] rounded-xl text-sm font-medium px-2"
                      key={id}
                    >
                      {e}{" "}
                      <button onClick={() => handleRemoveIp(id)}>
                        <ClearIcon color="error" className="cursor-pointer" />
                      </button>{" "}
                    </span>
                  );
                })}
              </div>
            </>
          )}
          <div className="my-4 flex gap-4">
            <Button onClick={handlePrevious}>Previous</Button>
            <Button variant="contained" onClick={handleNext}>
              Next
            </Button>
          </div>
        </div>
      )}
      {showInviteTab && activeTab === 4 && (
        <div>
          <Typography variant="h6">Do you want to add allowed network?</Typography>

          <div className="my-10 flex gap-4 items-center">
            <TextField
              type="email"
              sx={{ width: 250 }}
              value={ip}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleAddIP();
                }
              }}
              onChange={(e) => {
                setIp(e.target.value);
              }}
              id="outlined-basic"
              label="Enter IP address"
              variant="outlined"
            />
            <Button variant="contained" onClick={handleAddIP}>
              ADD
            </Button>
          </div>
          {ips.length > 0 && (
            <>
              <div className="w-full bg-slate-100 flex gap-2 flex-wrap rounded-lg p-2">
                {ips.map((e, id) => {
                  return (
                    <span
                      className="bg-[#b0c8dc] rounded-xl text-sm font-medium px-2"
                      key={id}
                    >
                      {e}{" "}
                      <button onClick={() => handleRemoveIp(id)}>
                        <ClearIcon color="error" className="cursor-pointer" />
                      </button>{" "}
                    </span>
                  );
                })}
              </div>
            </>
          )}
          <div className="my-4 flex gap-4">
            <Button onClick={handlePrevious}>Previous</Button>
            <Button variant="contained" onClick={handleNext}>
              Next
            </Button>
          </div>
        </div>
      )}

      {activeTab === 5 && (
        <div>
          <Typography variant="h6">Are You Sure?</Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <Button onClick={handlePrevious}>Previous</Button>
            <Button onClick={handleSubmit}>{submitloading ? <FontAwesomeIcon
              icon={faSpinner}
              spin
            // style={{ marginLeft: "8px" }}
            /> : "Submit"}</Button>
          </div>
        </div>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Createassesment;
