import {
  Box,
  Button,
  Container,
  Input,
  Paper,
  Typography,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import jwtDecode from "jwt-decode";
import AssessCard from "../../components/Assessment/AssessCard";
import { useEffect, useState } from "react";
import testService from "../../store/services/test.service";

function User() {
  const [testCode, setTestCode] = useState("");
  const [allTests, setTests] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");

  const handleOpenModal = (content) => {
    setModalContent(content);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const register = () => {
    testService
      .registerTest(jwtDecode(token).id, testCode, jwtDecode(token).company)
      .then((res) => {
        const content = `Test has been  ${res.testStatus} Now `;
        setModalContent(content);
        handleOpenModal(content);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        if (err.response && err.response.status === 400 && err.response.data.message === "Error: Duplicate entry") {
          const content = `Already enrolled in test as there's only one attempt for test`;
          setModalContent(content);
          handleOpenModal(content);
          setTimeout(() => {
            window.location.reload();
          }, 1700);
        } else if (err.response && err.response.status === 500 && err.response.data.message === "Failed to add invitations , wrong code   ") {
          const content = `No Test available with this code. Please enter the correct code`;
          setModalContent(content);
          handleOpenModal(content);
          setTimeout(() => {
            window.location.reload();
          }, 1700);
        }
      });
  };

  const handleTestCode = (e) => {
    setTestCode(e.target.value);
  };

  useEffect(() => {
    // setTimeout(() => {
    //   testService
    //   .getTests(jwtDecode(token).id, null)
    //   .then((res) => {
    //     setTests(res.tests);
    //   })
    //   .catch((err) => {
    //   });
    // }, 10000);
  }, []);

  return (
    <Container sx={{ p: 4 }}>
      <Paper
        elevation={4}
        sx={{
          p: 1,
          display: "flex",
          gap: 8,
          alignItems: "center",
          justifyContent: "center",
          mt: 5,
        }}
      >
        <Typography fontWeight={800}>Enter Test Code To Join : </Typography>
        <Input
          sx={{ width: "60%" }}
          placeholder="ssxHu22"
          value={testCode}
          onChange={handleTestCode}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              register();
            }
          }}
          required
        />

        <Button variant="contained" onClick={register}>
          Submit
        </Button>
      </Paper>

      <Typography fontSize={17} fontWeight={800} variant="body1" sx={{ my: 5 }}>
        Your Pending Tests
      </Typography>
      <Box>
        {allTests &&
          allTests
            .filter((test) => test.testStatus !== 'Expired' && test.testStatus !== 'not started')
            .map((test) => (
              <AssessCard
                key={test.id}
                testObject={test}
                alltest={allTests}
                handleOpenModal={handleOpenModal}
              />
            ))}
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="transition-modal-title" variant="h6" component="h2"></Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {modalContent}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
}

export default User;
