import {
  faClock,
  faQuestionCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Popover,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SubAdminSelector from "../testpermission/testpermission";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import ClearIcon from "@mui/icons-material/Clear";

import Test, {
  reloadPage,
  updateDuration,
  updateExpireDate,
} from "../../store/actions/test";
import testService from "../../store/services/test.service";

import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { iparhaiServer } from "../../store/services/config/api.config";
import TestTable from "../ScheduleTest/TestTable";
import moment from "moment";
import momenttz from "moment-timezone"
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { formatDate } from "../../utils/sceduleCard.function";

function AssessCard({ test, toggle, ShowScore, availableMinutes, getAllTest }) {
  const socketState = useSelector((state) => state.messageReducer.socketState);
  const dispatch = useDispatch();

  const requestFullScreen = () => {
    const elem = document.documentElement; // Apply to the entire document
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      // IE/Edge
      elem.msRequestFullscreen();
    }
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const companyId = localStorage.getItem("cid");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customAlert, setCustomAlert] = useState([false, ""]);
  const [ips, setips] = useState(test.IPs ? test.IPs.split("/") : []);
  const [openAnalytics, setOpenAnalytics] = useState(false);
  const [analyticsRow, setAnalyticsRow] = useState([]);
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState([false, ""]);
  const [emailInput, setEmailInput] = useState("");
  const [ip, setIp] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElTimezone, setAnchorElTimezone] = useState(null);
  const [updateData, setUpdateData] = useState({
    name: test.name,
    description: test.description,
    startdate: moment.tz(test.startdate, test.timezone).format('YYYY-MM-DD HH:mm:ss'),
    expiredate: moment.tz(test.expiredate, test.timezone).format('YYYY-MM-DD HH:mm:ss'),
    duration: test.duration / 60,
    marksToBeDeducted: test.marksToBeDeducted,
    gradelevel: test.gradelevel,
    timezone: test.timezone
  });
  const [updateExpiryTime, setUpdateExpiryTime] = useState(null);
  const [updateId, setUpdateId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [openGuidelineModal, setOpenGuidelineModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [processing, setProcessing] = useState(false);

  const curloc = location.pathname;

  const numberOfQuestions =
    test.questions && Object.keys(test.questions).length;

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [openpermissionModal, setOpenpermissionModal] = useState(false);
  const handlepermissionOpen = () => setOpenpermissionModal(true);
  const handlepermissionClose = () => setOpenpermissionModal(false);

  const handleStartClick = async () => {
    localStorage.setItem("tid", test.id);
    localStorage.setItem("cid", test.companyId);
    setProcessing(true);
    if (jwtDecode(token).role !== 2) {
      let res = await testService.pingrequest(test.id);
      if (res.status === 401) {
        navigate("/login");
        return;
      } else if (!res) {
        return;
      } else if (res.status === 404) {
        // console.log(res.data.error)
        alert(res.data.error);
        setProcessing(false);
        return;
      } else {
        let currentTime = moment();
        let expireDate = moment(test.expiredate);
        let diffFromLocalToExpire = expireDate.diff(currentTime) / 1000;

        if (diffFromLocalToExpire < 1) {
          alert("test expired.");
          setProcessing(false);
          return;
        }
        let remainingTime;
        if (diffFromLocalToExpire > test.remainingTime) {
          remainingTime = test.remainingTime;
        } else {
          remainingTime = diffFromLocalToExpire;
        }
        setTimeout(() => {
          dispatch(Test.updateQuestions(test.questions));
          // dispatch(Test.updateDuration(Math.floor(test.remainingTime)));
          dispatch(updateDuration(Math.floor(remainingTime)));

          dispatch(Test.updateMode(test.QuestionType));
          dispatch(Test.updateaiproctor(test.enableAiVigilance));
          dispatch(
            Test.updateenableNegativeMarking(test.enableNegativeMarking)
          );
          dispatch(Test.updatemarksToBeDeducted(test.marksToBeDeducted));

          if (test.enableAiVigilance) {
            dispatch(Test.updateExpireDate(test.expiredate));
            navigate("/guidelines");
          } else if (
            test.QuestionType === "Text-based" ||
            test.QuestionType === "MCQ-based"
          ) {
            setOpenGuidelineModal(true);
          } else {
            navigate("/test");
          }
          setProcessing(false);
        }, 2000);
      }
    }
  };

  const fetchIp = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  //  console.log(test.QuestionType,"here")
  const deleteTest = async () => {
    try {
      testService
        .deleteTest(test.id)
        .then((res) => {
          // setError([false, ""]);
          window.location.reload();
        })
        .catch((err) => {
          // setError([true, "Error deleting test"]);
          alert(err);
        });
      // console.log('sdfsfsdfsdobject')
    } catch (error) {
      alert(error);
    }
  };

  const handleAddIP = () => {
    // Regular expression pattern to validate IP address (IPv4) with at least three dots
    const ipPattern = /^(\d{1,3}\.){3}\d{1,3}$/;

    // Trim whitespace from the entered IP and check if it matches the pattern
    if (ip.trim() === "" || !ipPattern.test(ip.trim())) {
      // Invalid IP address, handle error or alert
      setError([
        true,
        "Invalid IP address. Please enter a valid IPv4 address with at least three dots.",
      ]);
      return;
    }

    // IP is valid, add it to the ips state
    setips([...ips, ip.trim()]);
    setIp("");
  };

  const handleTestStarted = async (userId, testId) => {
    try {
      const res = await iparhaiServer.get(
        `/api/test/getSingleInvitation/${userId}&${testId}`
      );
      setError([true, "Successfully Updated"]);
      setTimeout(() => {
        setError([false, ""]);
      }, 2000);
      getUsersOfThisTest();
    } catch (error) {
      // console.log(error.response.data.message)
    }
  };

  const getUsersOfThisTest = async () => {
    try {
      let res = await iparhaiServer.get(
        `/api/test/getInvitationsOfTest/${test.id}`
      );
      setAnalyticsRow(res.data);
      // console.log(res.data)
    } catch (error) { }
  };

  const handleUpdateExpiryTime = async () => {
    try {
      const res = await iparhaiServer.post(
        `/api/test/updateExpireTime/${test.id}&${updateId}`,
        { time: updateExpiryTime }
      );
      console.log({ updateId, companyId });
      socketState.emit("send-message", {
        message: "--RELOAD--",
        recipientId: updateId,
        userId: companyId,
        sender: "admin",
      });

      setError([true, "Successfully Updated"]);
      setOpenUpdateModal(false);
      setTimeout(() => {
        setError([false, ""]);
      }, 2000);
      getUsersOfThisTest();
    } catch (error) {
      console.log(error);
    }
  };

  const handlePopoverClose = () => {
    setAnchorElTimezone(null);
  };

  const timeZones = [
    "Africa/Abidjan",
    "Africa/Accra",
    "Africa/Addis_Ababa",
    "Africa/Algiers",
    "Africa/Asmara",
    "Africa/Bamako",
    "Africa/Bangui",
    "Africa/Banjul",
    "Africa/Bissau",
    "Africa/Blantyre",
    "Africa/Brazzaville",
    "Africa/Bujumbura",
    "Africa/Cairo",
    "Africa/Casablanca",
    "Africa/Ceuta",
    "Africa/Conakry",
    "Africa/Dakar",
    "Africa/Dar_es_Salaam",
    "Africa/Djibouti",
    "Africa/Douala",
    "Africa/El_Aaiun",
    "Africa/Freetown",
    "Africa/Gaborone",
    "Africa/Harare",
    "Africa/Johannesburg",
    "Africa/Juba",
    "Africa/Kampala",
    "Africa/Khartoum",
    "Africa/Kigali",
    "Africa/Kinshasa",
    "Africa/Lagos",
    "Africa/Libreville",
    "Africa/Lome",
    "Africa/Luanda",
    "Africa/Lubumbashi",
    "Africa/Lusaka",
    "Africa/Malabo",
    "Africa/Maputo",
    "Africa/Maseru",
    "Africa/Mbabane",
    "Africa/Mogadishu",
    "Africa/Monrovia",
    "Africa/Nairobi",
    "Africa/Ndjamena",
    "Africa/Niamey",
    "Africa/Nouakchott",
    "Africa/Ouagadougou",
    "Africa/Porto-Novo",
    "Africa/Sao_Tome",
    "Africa/Tripoli",
    "Africa/Tunis",
    "Africa/Windhoek",
    "America/Adak",
    "America/Anchorage",
    "America/Anguilla",
    "America/Antigua",
    "America/Araguaina",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Catamarca",
    "America/Argentina/Cordoba",
    "America/Argentina/Jujuy",
    "America/Argentina/La_Rioja",
    "America/Argentina/Mendoza",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Salta",
    "America/Argentina/San_Juan",
    "America/Argentina/San_Luis",
    "America/Argentina/Tucuman",
    "America/Argentina/Ushuaia",
    "America/Aruba",
    "America/Asuncion",
    "America/Atikokan",
    "America/Bahia",
    "America/Bahia_Banderas",
    "America/Barbados",
    "America/Belem",
    "America/Belize",
    "America/Blanc-Sablon",
    "America/Boa_Vista",
    "America/Bogota",
    "America/Boise",
    "America/Cambridge_Bay",
    "America/Campo_Grande",
    "America/Cancun",
    "America/Caracas",
    "America/Cayenne",
    "America/Cayman",
    "America/Chicago",
    "America/Chihuahua",
    "America/Costa_Rica",
    "America/Creston",
    "America/Cuiaba",
    "America/Curacao",
    "America/Danmarkshavn",
    "America/Dawson",
    "America/Dawson_Creek",
    "America/Denver",
    "America/Detroit",
    "America/Dominica",
    "America/Edmonton",
    "America/Eirunepe",
    "America/El_Salvador",
    "America/Fort_Nelson",
    "America/Fortaleza",
    "America/Glace_Bay",
    "America/Goose_Bay",
    "America/Grand_Turk",
    "America/Grenada",
    "America/Guadeloupe",
    "America/Guatemala",
    "America/Guayaquil",
    "America/Guyana",
    "America/Halifax",
    "America/Havana",
    "America/Hermosillo",
    "America/Indiana/Indianapolis",
    "America/Indiana/Knox",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Tell_City",
    "America/Indiana/Vevay",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Inuvik",
    "America/Iqaluit",
    "America/Jamaica",
    "America/Juneau",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Kralendijk",
    "America/La_Paz",
    "America/Lima",
    "America/Los_Angeles",
    "America/Lower_Princes",
    "America/Maceio",
    "America/Managua",
    "America/Manaus",
    "America/Marigot",
    "America/Martinique",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Menominee",
    "America/Merida",
    "America/Metlakatla",
    "America/Mexico_City",
    "America/Miquelon",
    "America/Moncton",
    "America/Monterrey",
    "America/Montevideo",
    "America/Montserrat",
    "America/Nassau",
    "America/New_York",
    "America/Nipigon",
    "America/Nome",
    "America/Noronha",
    "America/North_Dakota/Beulah",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/Nuuk",
    "America/Ojinaga",
    "America/Panama",
    "America/Pangnirtung",
    "America/Paramaribo",
    "America/Phoenix",
    "America/Port-au-Prince",
    "America/Port_of_Spain",
    "America/Porto_Velho",
    "America/Puerto_Rico",
    "America/Punta_Arenas",
    "America/Rainy_River",
    "America/Rankin_Inlet",
    "America/Recife",
    "America/Regina",
    "America/Resolute",
    "America/Rio_Branco",
    "America/Santarem",
    "America/Santiago",
    "America/Santo_Domingo",
    "America/Sao_Paulo",
    "America/Scoresbysund",
    "America/Sitka",
    "America/St_Barthelemy",
    "America/St_Johns",
    "America/St_Kitts",
    "America/St_Lucia",
    "America/St_Thomas",
    "America/St_Vincent",
    "America/Swift_Current",
    "America/Tegucigalpa",
    "America/Thule",
    "America/Thunder_Bay",
    "America/Tijuana",
    "America/Toronto",
    "America/Tortola",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Winnipeg",
    "America/Yakutat",
    "America/Yellowknife",
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/DumontDUrville",
    "Antarctica/Macquarie",
    "Antarctica/Mawson",
    "Antarctica/McMurdo",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/Syowa",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "Arctic/Longyearbyen",
    "Asia/Aden",
    "Asia/Almaty",
    "Asia/Amman",
    "Asia/Anadyr",
    "Asia/Aqtau",
    "Asia/Aqtobe",
    "Asia/Ashgabat",
    "Asia/Atyrau",
    "Asia/Baghdad",
    "Asia/Bahrain",
    "Asia/Baku",
    "Asia/Bangkok",
    "Asia/Barnaul",
    "Asia/Beirut",
    "Asia/Bishkek",
    "Asia/Brunei",
    "Asia/Chita",
    "Asia/Choibalsan",
    "Asia/Colombo",
    "Asia/Damascus",
    "Asia/Dhaka",
    "Asia/Dili",
    "Asia/Dubai",
    "Asia/Dushanbe",
    "Asia/Famagusta",
    "Asia/Gaza",
    "Asia/Hebron",
    "Asia/Ho_Chi_Minh",
    "Asia/Hong_Kong",
    "Asia/Hovd",
    "Asia/Irkutsk",
    "Asia/Jakarta",
    "Asia/Jayapura",
    "Asia/Jerusalem",
    "Asia/Kabul",
    "Asia/Kamchatka",
    "Asia/Karachi",
    "Asia/Kathmandu",
    "Asia/Khandyga",
    "Asia/Kolkata",
    "Asia/Krasnoyarsk",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Asia/Kuwait",
    "Asia/Macau",
    "Asia/Magadan",
    "Asia/Makassar",
    "Asia/Manila",
    "Asia/Muscat",
    "Asia/Nicosia",
    "Asia/Novokuznetsk",
    "Asia/Novosibirsk",
    "Asia/Omsk",
    "Asia/Oral",
    "Asia/Phnom_Penh",
    "Asia/Pontianak",
    "Asia/Pyongyang",
    "Asia/Qatar",
    "Asia/Qostanay",
    "Asia/Qyzylorda",
    "Asia/Riyadh",
    "Asia/Sakhalin",
    "Asia/Samarkand",
    "Asia/Seoul",
    "Asia/Shanghai",
    "Asia/Singapore",
    "Asia/Srednekolymsk",
    "Asia/Taipei",
    "Asia/Tashkent",
    "Asia/Tbilisi",
    "Asia/Tehran",
    "Asia/Thimphu",
    "Asia/Tokyo",
    "Asia/Tomsk",
    "Asia/Ulaanbaatar",
    "Asia/Urumqi",
    "Asia/Ust-Nera",
    "Asia/Vientiane",
    "Asia/Vladivostok",
    "Asia/Yakutsk",
    "Asia/Yangon",
    "Asia/Yekaterinburg",
    "Asia/Yerevan",
    "Atlantic/Azores",
    "Atlantic/Bermuda",
    "Atlantic/Canary",
    "Atlantic/Cape_Verde",
    "Atlantic/Faroe",
    "Atlantic/Madeira",
    "Atlantic/Reykjavik",
    "Atlantic/South_Georgia",
    "Atlantic/St_Helena",
    "Atlantic/Stanley",
    "Australia/Adelaide",
    "Australia/Brisbane",
    "Australia/Broken_Hill",
    "Australia/Currie",
    "Australia/Darwin",
    "Australia/Eucla",
    "Australia/Hobart",
    "Australia/Lindeman",
    "Australia/Lord_Howe",
    "Australia/Melbourne",
    "Australia/Perth",
    "Australia/Sydney",
    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Astrakhan",
    "Europe/Athens",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Busingen",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Guernsey",
    "Europe/Helsinki",
    "Europe/Isle_of_Man",
    "Europe/Istanbul",
    "Europe/Jersey",
    "Europe/Kaliningrad",
    "Europe/Kiev",
    "Europe/Kirov",
    "Europe/Lisbon",
    "Europe/Ljubljana",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Mariehamn",
    "Europe/Minsk",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Oslo",
    "Europe/Paris",
    "Europe/Podgorica",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/San_Marino",
    "Europe/Sarajevo",
    "Europe/Saratov",
    "Europe/Simferopol",
    "Europe/Skopje",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Ulyanovsk",
    "Europe/Uzhgorod",
    "Europe/Vaduz",
    "Europe/Vatican",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Zaporozhye",
    "Europe/Zurich",
    "Indian/Antananarivo",
    "Indian/Chagos",
    "Indian/Christmas",
    "Indian/Cocos",
    "Indian/Comoro",
    "Indian/Kerguelen",
    "Indian/Mahe",
    "Indian/Maldives",
    "Indian/Mauritius",
    "Indian/Mayotte",
    "Indian/Reunion",
    "Pacific/Apia",
    "Pacific/Auckland",
    "Pacific/Bougainville",
    "Pacific/Chatham",
    "Pacific/Chuuk",
    "Pacific/Easter",
    "Pacific/Efate",
    "Pacific/Enderbury",
    "Pacific/Fakaofo",
    "Pacific/Fiji",
    "Pacific/Funafuti",
    "Pacific/Galapagos",
    "Pacific/Gambier",
    "Pacific/Guadalcanal",
    "Pacific/Guam",
    "Pacific/Honolulu",
    "Pacific/Kiritimati",
    "Pacific/Kosrae",
    "Pacific/Kwajalein",
    "Pacific/Majuro",
    "Pacific/Marquesas",
    "Pacific/Midway",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Norfolk",
    "Pacific/Noumea",
    "Pacific/Pago_Pago",
    "Pacific/Palau",
    "Pacific/Pitcairn",
    "Pacific/Pohnpei",
    "Pacific/Port_Moresby",
    "Pacific/Rarotonga",
    "Pacific/Saipan",
    "Pacific/Tahiti",
    "Pacific/Tarawa",
    "Pacific/Tongatapu",
    "Pacific/Wake",
    "Pacific/Wallis",
  ];


  const analyticsColumns = [
    {
      field: "email",
      headerName: "Email",
      width: 180,
      flex: 1,
      align: "left",
    },
    {
      field: "startTime",
      headerName: "Start Time",
      width: 180,
      flex: 1,
      align: "left",
      renderCell: (params) => (
        <p>{params.row.startTime?.split("T").join(" ") || "Not started"}</p>
      ),
    },
    {
      field: "expireTime",
      headerName: "Expire Time",
      width: 180,
      flex: 1,
      align: "left",
      renderCell: (params) => (
        <p>
          {params.row.expireTime?.split("T").join(" ") ||
            moment(test.expiredate)
              .format("YYYY-MM-DDTHH:mm")
              .split("T")
              .join(" ")}
        </p>
      ),
    },
    {
      field: "finishTime",
      headerName: "Finish Time",
      width: 180,
      flex: 1,
      align: "left",
      renderCell: (params) => (
        <p>{params.row.finishTime?.split("T").join(" ") || ""}</p>
      ),
    },
    {
      field: "testStarted",
      headerName: "Test Started",
      width: 180,
      flex: 1,
      align: "left",
      renderCell: (params) => (
        <Button
          disabled={params.row.testStarted === false}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleTestStarted(params.row.id, test.id)}
        >
          {params.row.testStarted ? "Restart" : "Not Started"}
        </Button>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 180,
      flex: 1,
      align: "left",
      renderCell: (params) => (
        <EditNoteRoundedIcon
          onClick={() => {
            setUpdateExpiryTime(params.row.expireTime);
            setUpdateId(params.row.id);
            setOpenUpdateModal(true);
          }}
          className="text-green-600 cursor-pointer hover:scale-90"
          fontSize="large"
        />
      ),
    },
  ];

  const handleRemoveIp = (index) => {
    setips(ips.filter((_, i) => i !== index));
  };

  const handleRemoveEmail = (index) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  const handleAddInviteLinks = () => {
    const maxEmails = availableMinutes / test.duration; // Calculate the maximum number of emails based on the total time and test duration

    if (validateEmail(emailInput)) {
      if (emails.includes(emailInput)) {
        setError([true, "Email already exists"]);
        return;
      }
      if (emails.length >= Math.floor(maxEmails)) {
        setError([true, `You can only add ${Math.floor(maxEmails)} emails`]);
        return;
      }
      setEmails([...emails, emailInput]);
      setEmailInput("");
    } else {
      setError([true, "Invalid Email"]);
    }
  };

  const validateEmail = (email) => {
    const atSymbolIndex = email.indexOf("@");
    return (
      atSymbolIndex > 0 &&
      atSymbolIndex < email.length - 1 &&
      email.indexOf("@", atSymbolIndex + 1) === -1
    );
  };
  const handleOpeninfo = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleUpdateTest = async () => {
    // Validation for each field
    if (!updateData.name.trim()) {
      setCustomAlert([true, "Please fill the Name field"]);
      return;
    }

    if (!updateData.description.trim()) {
      setCustomAlert([true, "Please fill the Description field"]);
      return;
    }

    if (!updateData.duration) {
      setCustomAlert([true, "Please fill the Duration field"]);
      return;
    }

    if (test.QuestionType === "Text-based" && !updateData.gradelevel) {
      setCustomAlert([true, "Please select the Grade level"]);
      return;
    }

    try {
      setProcessing(true);
      let data = await iparhaiServer.patch(`/api/test/editTest/${test.id}`, {
        ...updateData,
        ips,
        emails,
      });
      setProcessing(false);
      setEmails([]);
      setIsModalOpen(false);
      setError([true, "Successfully Updated"]);
      setTimeout(() => {
        setError([false, ""]);
      }, 2000);
      getAllTest();
    } catch (error) {
      setProcessing(false);
      setError([true, error.response.data.message]);
    }
  };


  const handleOpenAnalytics = async () => {
    getUsersOfThisTest();
    setOpenAnalytics(true);
    try {
    } catch (error) {
      // console.log(error)
    }
  };

  const remainingTime = isNaN(test.remainingTime)
    ? test.duration
    : test.remainingTime;

  return (
    <>
      <Paper
        sx={{
          margin:
            curloc === "/scheduled"
              ? {
                xs: "0 auto",
                md: "0",
              }
              : "0",
          borderRadius: "0.5rem",
          position: "relative",
          width: {
            xs: curloc === "/scheduled" ? "90%" : "16rem", //  between 0px and 600px
            sm: curloc === "/scheduled" ? "100%" : "50%", //  between 600px and 960px
            md: curloc === "/scheduled" ? "45%" : "30%", //  between 960px and 1280px
            lg: curloc === "/scheduled" ? "35%" : "30%", // between 1280px and 1920px
          },
          height: "max-content",
          padding: "0.5rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx >

        </Box>
        <div className="absolute rounded-xl shadow-green-900 border border-green-500 shadow-xl -top-2 px-2 text-white text-sm z-[60] bg-green-600">
          {test.timezone}
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "primary.lighter",
            width: "100%",
            minHeight: "10.5rem",
            borderRadius: "0.25rem",
            padding: "0.5rem 0",
            position: "relative",
          }}

        >
          {jwtDecode(token).role === 1 && (
            <div className="flex flex-col">
              {test.timezone ? (
                <>
                  <p className="text-base font-medium">
                    Start Time:{" "}
                    <span className="text-xs font-normal">
                      {formatDate(test.startdate, test.timezone)}
                    </span>
                  </p>
                  <p className="text-base font-medium">
                    Expiry Time:{" "}
                    <span className="text-xs font-normal">
                      {formatDate(test.expiredate, test.timezone)}
                    </span>
                  </p>
                  <p className="mt-2 font-semibold">Local Time,</p>
                </>
              ) : (
                <p className="mt-2 font-semibold">Local Time,</p>
              )}
              <p className="text-base font-medium">
                Start Time:{" "}
                <span className="text-xs font-normal">
                  {formatDate(test.startdate)}
                </span>
              </p>
              <p className="text-base font-medium">
                Expiry Time:{" "}
                <span className="text-xs font-normal">
                  {formatDate(test.expiredate)}
                </span>
              </p>
            </div>

          )}
          {jwtDecode(token).role === 2 ? (
            curloc === "/dashboard" ? (
              <div className="flex items-start justify-between px-3">
                <div className="flex flex-col">
                  {test.timezone ? (
                    <>
                      <p className="text-base font-medium">
                        Start Time:{" "}
                        <span className="text-xs font-normal">
                          {formatDate(test.startdate, test.timezone)}
                        </span>
                      </p>
                      <p className="text-base font-medium">
                        Expiry Time:{" "}
                        <span className="text-xs font-normal">
                          {formatDate(test.expiredate, test.timezone)}
                        </span>
                      </p>
                      <p className="mt-2 font-semibold">Local Time,</p>
                    </>
                  ) : (
                    <p className="mt-2 font-semibold">Local Time,</p>
                  )}
                  <p className="text-base font-medium">
                    Start Time:{" "}
                    <span className="text-xs font-normal">
                      {formatDate(test.startdate)}
                    </span>
                  </p>
                  <p className="text-base font-medium">
                    Expiry Time:{" "}
                    <span className="text-xs font-normal">
                      {formatDate(test.expiredate)}
                    </span>
                  </p>
                </div>

                <Button
                  onClick={() => setDeleteModal(true)}
                  variant="outlined"
                  color="primary"
                  sx={{
                    color: "primary",
                    padding: "0",
                    width: "fit-content",
                    fontSize: "0.8rem",
                    fontWeight: 600,
                  }}
                >
                  Delete
                </Button>
              </div>
            ) : (
              <></>
            )
          ) : jwtDecode(token).role === 4 ? (
            curloc === "/dashboard" ? (

              <Button
                onClick={() => setDeleteModal(true)}
                variant="outlined"
                color="primary"
                sx={{
                  color: "primary",
                  padding: "0",
                  width: "fit-content",
                  alignSelf: "flex-end",
                  mr: "0.25rem",
                  fontSize: "0.8rem",
                  fontWeight: 600,
                }}
              >
                Delete
              </Button>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              padding: "0.5rem",
              pt: "2.5rem",
            }}
          >
            <Tooltip title={test.name} placement="top">
              <Typography
                className="w-fit"
                variant="h6"
                sx={{
                  fontSize: "1.125rem",
                  display: "inline",
                }}
                fontWeight={800}
              >
                {test.name.length > 40
                  ? test.name.slice(0, 40) + "..."
                  : test.name}
              </Typography>
            </Tooltip>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "0.8rem",
                }}
              >
                <FontAwesomeIcon icon={faQuestionCircle} />{" "}
                {`${numberOfQuestions || test.questionCount} Questions`}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "0.8rem",
                }}
              >
                <FontAwesomeIcon icon={faClock} />{" "}
                {`${(remainingTime / 60).toFixed(1)} Minute${remainingTime > 1 ? "s" : ""
                  }`}
              </Typography>
              {test.code && jwtDecode(token).role === 2 && (
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: 800,
                  }}
                >
                  {test.inviteMethod === 2
                    ? "Invite link sent"
                    : `Code: ${test.code}`}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Typography
          sx={{
            mt: 0.5,
            mb: 2,
            fontSize: {
              xs: "0.7rem", //  between 0px and 600px
              sm: "0.7rem", //  between 600px and 960px
              md: "0.6rem", //  between 960px and 1280px
              lg: "0.7rem", // between 1280px and 1920px
              xl: "0.875rem", //  above 1920px
            },
            lineHeight: "1rem",
            textAlign: "left",
            height: "5rem",
          }}
          variant="subtitle2"
          color={"gray"}
          display={"block"}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "0.7rem", //  between 0px and 600px
                sm: "0.7rem", //  between 600px and 960px
                md: "0.6rem", //  between 960px and 1280px
                lg: "0.7rem", // between 1280px and 1920px
                xl: "0.875rem", //  above 1920px
              },
            }}
            variant="subtitle2"
            color="black"
            fontWeight={800}
            component={"span"}
          >
            Description :{" "}
          </Typography>

          {test.description}
        </Typography>
        {(jwtDecode(token).role === 2 || jwtDecode(token).role === 4) && (
          <div className="flex my-2" style={{ display: 'flex', alignItems: 'center' }}>
            {/* New Button */}
            <div>
              <Button
                variant="outlined"
                color="info"
                onClick={handlepermissionOpen}
                sx={{ marginRight: 1 }}
              >
                Permissions
              </Button>
              <SubAdminSelector testid={test.id} open={openpermissionModal} onClose={handlepermissionClose} />
            </div>




            {/* Analytics Button */}
            <Button
              variant="outlined"
              color="info"
              onClick={handleOpenAnalytics}
              sx={{ marginRight: 1 }}  // Space between the Analytics button and the IconButton
            >
              Analytics
            </Button>

            {/* IconButton */}
            <IconButton color="info" onClick={handleOpeninfo}>
              <InfoIcon />
            </IconButton>

            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity="info"
                sx={{ width: "100%" }}
              >
                This Analytics is to monitor Enrollments of Number of candidates
                and Restart the test if needed
              </Alert>
            </Snackbar>
          </div>
        )}



        <Button
          id="startTestButton"
          variant="contained"
          size="small"
          sx={{
            borderRadius: "0.5rem",
            fontSize: "1rem",
            minHeight: "36px",
            position: "relative",
          }}
          onClick={async (e) => {
            if (jwtDecode(token).role === 2 || jwtDecode(token).role === 4) {
              if (curloc === "/dashboard") {
                openModal();
              } else {
                toggle();
              }
            } else {
              if (test.IPs) {
                setProcessing(true);
                const ip = await fetchIp();
                let IPs = test.IPs.split("/");

                if (IPs.includes(ip)) {
                  handleStartClick();
                } else {
                  alert("Your IP is Restricted");
                  setProcessing(false);
                  return;
                }
              } else {
                handleStartClick();
              }
            }
          }}
        >
          {processing ? (
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              color="#FFF"
              size="1x"
              style={{
                position: "absolute",
                left: "50%",
                top: "40%",
                transform: "translate(-50%, -50%)",
              }} // Center the spinner
            />
          ) : // Show appropriate text based on role and curloc
            jwtDecode(token).role === 2 || jwtDecode(token).role === 4 ? (
              curloc === "/dashboard" ? (
                "Edit Assessment"
              ) : ShowScore ? (
                "Hide Report <"
              ) : (
                "View Report >"
              )
            ) : (
              "Start Test"
            )}
        </Button>

        {/* ***************** edit test *********************  */}
        <Dialog open={isModalOpen} onClose={closeModal} fullWidth>
          <DialogContent>
            {customAlert[0] &&
              (setTimeout(() => {
                setCustomAlert([false, ""]);
              }, 2000),
                (
                  <Alert
                    sx={{
                      width: "fit-content",
                    }}
                    severity="error"
                  >
                    {customAlert[1]}
                  </Alert>
                ))}
            <div className="flex gap-4 flex-col">
              <TextField
                value={updateData.name}
                onChange={(e) =>
                  setUpdateData({ ...updateData, name: e.target.value })
                }
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
              />
              <TextField
                value={updateData.description}
                onChange={(e) =>
                  setUpdateData({ ...updateData, description: e.target.value })
                }
                fullWidth
                id="outlined-basic"
                label="Description"
                variant="outlined"
              />
              <TextField
                fullWidth
                id="outlined-basic"
                type="number"
                label="Duration"
                variant="outlined"
                value={updateData.duration}
                onChange={(e) => {
                  const value = e.target.value;
                  // Ensure the value does not exceed 999
                  if (
                    value === "" ||
                    (Number(value) <= 999 && Number(value) >= 1)
                  ) {
                    setUpdateData({ ...updateData, duration: value });
                  }
                }}
              />
              {test.marksToBeDeducted && (
                <TextField
                  value={updateData.marksToBeDeducted}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      marksToBeDeducted: e.target.value,
                    })
                  }
                  fullWidth
                  id="outlined-basic"
                  label="Marks to be deducted"
                  variant="outlined"
                />
              )}
              {test.QuestionType === "Text-based" && (
                <Box display="flex" alignItems="center">
                  <TextField
                    // disabled={loading}
                    fullWidth
                    select
                    label="Grade level"
                    value={updateData.gradelevel}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        gradelevel: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="Primary">Primary</MenuItem>
                    <MenuItem value="Higher">High</MenuItem>
                    <MenuItem value="Intermediate">Intermediate</MenuItem>
                    <MenuItem value="Bachelors">Bachelors</MenuItem>
                    <MenuItem value="Masters">Masters</MenuItem>
                    <MenuItem value="Ph.D">Ph.D</MenuItem>
                  </TextField>
                  <IconButton
                    size="small"
                    aria-label="info"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    style={{ marginLeft: "8px" }}
                  >
                    <InfoIcon />
                  </IconButton>
                </Box>
              )}
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Typography style={{ margin: "12px", maxWidth: "300px" }}>
                  Information about Grade Levels. For taking admission tests,
                  please select one level below.
                </Typography>
              </Popover>
              <Box display="flex" alignItems="center" style={{ marginBottom: "16px" }}>
                <TextField
                  fullWidth
                  select
                  label="Time Zone"
                  value={updateData.timezone}
                  onChange={(e) => setUpdateData({...updateData , timezone:e.target.value})}
                >
                  {timeZones.map((zone, index) => (
                    <MenuItem key={index} value={zone}>
                      {zone}
                    </MenuItem>
                  ))}
                </TextField>
                <IconButton
                  size="small"
                  aria-label="info"
                  onClick={(e) => setAnchorElTimezone(e.currentTarget)}
                  style={{ marginLeft: "8px" }}
                >
                  <InfoIcon />
                </IconButton>
              </Box>
              <Popover
                open={Boolean(anchorElTimezone)}
                anchorEl={anchorElTimezone}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Typography style={{ margin: "12px", maxWidth: "300px" }}>
                  Select your test time zone from the list. This ensures that all scheduled activities and deadlines are displayed correctly according to this time zone.
                </Typography>
              </Popover>
              <div className="flex gap-4">
                <TextField
                  fullWidth
                  label="Start Date and Time"
                  type="datetime-local"
                  value={updateData.startdate}
                  onChange={(e) => {
                    setUpdateData({ ...updateData, startdate: e.target.value });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  fullWidth
                  label="End Date and Time"
                  type="datetime-local"
                  value={updateData.expiredate}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, expiredate: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="mt-2 flex gap-4 items-center">
                <TextField
                  type="email"
                  sx={{ width: 250 }}
                  value={ip}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleAddIP();
                    }
                  }}
                  onChange={(e) => {
                    setIp(e.target.value);
                  }}
                  id="outlined-basic"
                  label="Enter IP address"
                  variant="outlined"
                />
                <Button variant="contained" onClick={handleAddIP}>
                  ADD
                </Button>
              </div>
              {ips.length > 0 && (
                <>
                  <div className="w-full bg-slate-100 flex gap-2 flex-wrap rounded-lg p-2">
                    {ips.map((e, id) => {
                      if (e !== "") {
                        return (
                          <span
                            className="bg-[#b0c8dc] rounded-xl text-sm font-medium px-2"
                            key={id}
                          >
                            {e}{" "}
                            <button onClick={() => handleRemoveIp(id)}>
                              <ClearIcon
                                color="error"
                                className="cursor-pointer"
                              />
                            </button>{" "}
                          </span>
                        );
                      }
                    })}
                  </div>
                </>
              )}

              {test.inviteMethod === 2 && (
                <div className="mt-4 flex gap-4 items-center">
                  <TextField
                    type="email"
                    sx={{ width: 250 }}
                    value={emailInput}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleAddInviteLinks();
                      }
                    }}
                    onChange={(e) => {
                      setEmailInput(e.target.value);
                    }}
                    id="outlined-basic"
                    label="Enter Email"
                    variant="outlined"
                  />
                  <Button variant="contained" onClick={handleAddInviteLinks}>
                    ADD
                  </Button>
                </div>
              )}
              {emails.length > 0 && (
                <>
                  <div className="w-full bg-slate-100 flex gap-2 flex-wrap rounded-lg p-2">
                    {emails.map((e, id) => {
                      return (
                        <span
                          className="bg-[#b0c8dc] rounded-xl text-sm font-medium px-2"
                          key={id}
                        >
                          {e}{" "}
                          <button onClick={() => handleRemoveEmail(id)}>
                            <ClearIcon
                              color="error"
                              className="cursor-pointer"
                            />
                          </button>{" "}
                        </span>
                      );
                    })}
                  </div>
                </>
              )}

              <Button
                sx={{ marginTop: 5 }}
                onClick={handleUpdateTest}
                variant="contained"
                fullWidth
              >
                {processing ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    color="#FFF"
                    size="1x"
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                ) : (
                  " UPDATE"
                )}
              </Button>
            </div>
            {/* <Createassessment test={test} /> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* ****************** ANALYTICS *********************** */}
        <Dialog
          open={openAnalytics}
          onClose={() => setOpenAnalytics(false)}
          fullScreen
        >
          <DialogContent>
            <TestTable columns={analyticsColumns} rows={analyticsRow} />
          </DialogContent>
          <DialogActions>
            <div className="w-full flex justify-center">
              <Button
                variant="contained"
                onClick={() => setOpenAnalytics(false)}
                color="primary"
              >
                Close
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        {/* ********************  Update user data modal ****************** */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openUpdateModal}
          // onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openUpdateModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Update
              </Typography>
              <div className="my-3">
                <TextField
                  fullWidth
                  label="Expiry Time"
                  type="datetime-local"
                  value={updateExpiryTime}
                  onChange={(e) => setUpdateExpiryTime(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="flex gap-4 justify-center mt-4">
                <Button
                  variant="outlined"
                  color="success"
                  onClick={handleUpdateExpiryTime}
                >
                  Update
                </Button>
                <Button onClick={() => setOpenUpdateModal(false)}>
                  Cancel
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      </Paper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openGuidelineModal}
        // onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openGuidelineModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              // width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              // p: 4,
            }}
          >
            <div className="px-10 py-2">
              <Box
                id="yourContainerId"
                sx={{
                  marginBottom: 2,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 800,
                    fontSize: 30,
                    marginBottom: 1,
                    textAlign: "center",
                  }}
                >
                  Remember Candidate, Your Activity Will Be Monitored Throughout
                  the Test.
                </Typography>
                <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
                  {`instructions for ${test.QuestionType === "Text-based"
                    ? "Eassy-Based"
                    : "MCQ-Based"
                    } test`}
                </h2>
                <ul class=" space-y-1 text-gray-500 my-4 list-disc list-inside dark:text-gray-400">
                  {test.QuestionType === "MCQ-based" ? (
                    <>
                      <li>The test is time bounded.</li>
                      <li>
                        Please make sure you complete the test before time. If
                        you do not finish all the questions on time then only
                        attempted questions will be considered. However, for
                        your test to be considered, you must click the submit
                        button.
                      </li>
                      <li>
                        Each question has four choices. Please select the
                        desired choice and click the Next button.
                      </li>
                      <li>
                        Once you click the next button, the next question will
                        appear on the screen and the previously clicked question
                        will be submitted and cannot be changed. So please chose
                        your answers carefully.
                      </li>
                      <li>
                        You must check your choice before clicking the next
                        button. As there is no possibility of reviewing the test
                        at the end.
                      </li>
                      <li>
                        During the test do not switch tab or minimize the screen
                        as this activity will be monitored and may affect your
                        result.
                      </li>
                    </>
                  ) : (
                    <>
                      {/* <li>
                        The test is time bounded. It’s duration is from 12:30 PM
                        to 1:30 PM
                      </li> */}
                      <li>
                        Please make sure you complete the test before time.{" "}
                      </li>
                      <li>
                        During the test do not switch tab or minimize the screen
                        as this activity will be monitored and will affect your
                        result.
                      </li>
                      <li>
                        You are encouraged to repetitively save your test to
                        avoid any unexpected scenario.
                      </li>
                    </>
                  )}
                </ul>

                <div className="flex justify-center items-center gap-8">
                  <Button
                    variant="contained"
                    onClick={async () => {
                      let userId = jwtDecode(token).id;
                      try {
                        let currentTime = moment();
                        let expireDate = moment(test.expiredate);
                        let diffFromLocalToExpire =
                          expireDate.diff(currentTime) / 1000;

                        if (diffFromLocalToExpire < 1) {
                          alert("test expired.");
                          setProcessing(false);
                          return;
                        }
                        let remainingTime;
                        if (diffFromLocalToExpire > test.remainingTime) {
                          remainingTime = test.remainingTime;
                        } else {
                          remainingTime = diffFromLocalToExpire;
                        }
                        dispatch(updateDuration(Math.floor(remainingTime)));
                        await testService.testStarted(true, userId, test.id);
                        await iparhaiServer.post("api/test/testStartTime", {
                          testId: test.id,
                          userId,
                          testDuration: test.remainingTime,
                        });

                        navigate("/test");
                      } catch (err) {
                        // console.log("there is an error", err);
                        if (
                          err.response &&
                          err.response.status === 400 &&
                          err.response.data.message === "Test already started"
                        ) {
                          const content = `Only one Attempt is allowed by Admin. Please contact Test Admin to restart.`;
                          alert(content);
                          setProcessing(false);
                          return; // Stop execution if the specific error occurs
                        }
                      }
                      requestFullScreen();
                    }}
                    color="primary"
                  >
                    {processing ? (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        color="#FFF"
                        size="1x"
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                    ) : (
                      "Got It"
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpenGuidelineModal(false)}
                  >
                    Close
                  </Button>
                </div>
              </Box>
              {/* <div className="">
                <Button onClick={() => navigate("/test")} className="">
                  Next
                </Button>
              </div> */}
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* delete confirmation modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={!!deleteModal} // Ensure this evaluates to true or false
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={!!deleteModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            {deleteModal === "not allowed" ? (
              <>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Not Allowed
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  You are not allowed to delete this test.
                </Typography>
                <Button onClick={() => setDeleteModal(false)}>Close</Button>
              </>
            ) : deleteModal === true ? (
              <>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Delete Confirmation
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  Do you want to delete this test?
                </Typography>
                <div className="flex gap-4 justify-center mt-4">
                  <Button variant="outlined" color="error" onClick={deleteTest}>
                    Delete
                  </Button>
                  <Button onClick={() => setDeleteModal(false)}>Cancel</Button>
                </div>
              </>
            ) : null}
          </Box>
        </Fade>
      </Modal>

      {/* delete confirmation modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={deleteModal}
        // onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={deleteModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Delete Confirmation
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Do you want to delete this test?
            </Typography>
            <div className="flex gap-4 justify-center mt-4">
              <Button variant="outlined" color="error" onClick={deleteTest}>
                Delete
              </Button>
              <Button onClick={() => setDeleteModal(false)}>Cancel</Button>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Snackbar
        open={error[0]}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        // autoHideDuration={6000}
        onClose={() => setError([false, ...error])}
      >
        <Alert
          // onClose={handleClose}
          severity={
            error[1] === "Successfully Updated"
              ? "success"
              : error[1] !== ""
                ? "error"
                : ""
          }
          variant="filled"
          sx={{ width: "100%" }}
        >
          {error[1]}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AssessCard;
