import React, { useRef } from "react";
import SideBar from "../../components/Navigation/SideBar";
import {
  Alert,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Typography,
  IconButton,
  Snackbar,
} from "@mui/material";
import AssessCard from "../../components/Assessment/AssessCard";

import AddIcon from "@mui/icons-material/Add";
import jwtDecode from "jwt-decode";

import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import { useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Createassessment from "../../components/createassessment";
import InfoIcon from "@mui/icons-material/Info";

import Guidelines from "../Guidelines.jsx";

import { Pagination } from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import testService from "../../store/services/test.service.js";
import Adminsignup from "../../components/Account.js/Adminsignup.js";
import { Circles } from "react-loader-spinner";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { iparhaiServer } from "../../store/services/config/api.config.js";
import { fetchAndDecryptCode } from "../../utils/decryptedCode.js";
import { readFromExcel } from "../../utils/readfromexcel.js";
import { decrypt } from "../../components/encryptfunction/decrypt";
import authService from "../../store/services/auth.service.js";
import NetworkStatus from "../NetworkStatus.jsx";

function Assessments() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const encryptedCode = queryParams.get("code");

  const token = localStorage.getItem("token");
  const [open, setOpen] = useState(false);

  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [availableMinutes, setAvailableMinutes] = useState(null);
  const [testCode, setTestCode] = useState("");
  const [testCodeerr, settestCodeerr] = useState([false, ""]);
  const [openTestModal, setOpenTestModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const hasRegistered = useRef(false);
  let decryptedCode;
  let decryptedEmail;

  if (encryptedCode !== null) {
    try {
      decryptedCode = fetchAndDecryptCode(
        encryptedCode.split(".")[0],
        encryptedCode.split(".")[2]
      );
      // console.log(decryptedCode);
      decryptedEmail = fetchAndDecryptCode(
        encryptedCode.split(".")[1],
        encryptedCode.split(".")[2]
      );
      // console.log(decryptedEmail);
    } catch (error) {
      // console.log(error);
    }
    // decryptedCode = data;
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  let num = 0;
  const handleOpeninfo = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const register = (userId, code, company) => {
    // console.log(++num);
    if (testCode === "" && !code) {
      settestCodeerr([true, "Test Code is required"]);
      return;
    }
    if (!validate()) {
      return;
    }
    testService
      .registerTest(userId, code, company)
      .then((res) => {
        const content = `Test has been  ${res.testStatus} Now `;
        settestCodeerr([false, ""]);
        setModalContent(content);
        handleOpenTestModal(content);
        localStorage.removeItem("testCode");
        navigate("/dashboard");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        localStorage.removeItem("testCode");
        // console.log("there is an error", err);
        if (
          err.response &&
          err.response.status === 400 &&
          err.response.data.message === "Error: Duplicate entry"
        ) {
          const content = `Already enrolled in test as there's only one attempt for test`;
          setModalContent(content);
          handleOpenTestModal(content);
          setTimeout(() => {
            window.location.reload();
          }, 1700);
        } else if (
          err.response &&
          err.response.status === 500 &&
          err.response.data.message ===
            "Failed to add invitations , wrong code   "
        ) {
          const content = `No Test available with this code. Please enter the correct code`;
          setModalContent(content);
          handleOpenTestModal(content);
          setTimeout(() => {
            window.location.reload();
          }, 2200);
        }
      });
  };

  useEffect(() => {
    if (
      decryptedCode &&
      !hasRegistered.current &&
      jwtDecode(token).email === decryptedEmail
    ) {
      // console.log("user match");
      hasRegistered.current = true;
      register(jwtDecode(token).id, decryptedCode, jwtDecode(token).company);
      navigate("/dashboard");
    } else if (
      decryptedCode &&
      !hasRegistered.current &&
      jwtDecode(token).role !== 2
    ) {
      localStorage.removeItem("testCode");
      hasRegistered.current = true;
      // console.log("user dont match");
      setOpenTestModal(true);
      setModalContent("This Link is Associated with another User.");
      if (encryptedCode !== null) {
        localStorage.setItem("testCode", encryptedCode);
      }
      setTimeout(() => {
        setOpenTestModal(false);
        setModalContent("");
        navigate("/dashboard");
      }, 3000);

      // navigate("/dashboard")
    }
  }, [encryptedCode, token, navigate]);

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.id;
      const userRole = decodedToken.role;

      if (userRole === 2 || userRole === 4) {
        setLoading(true);
        testService
          .getAllTests(userId)
          .then((res) => {
            // console.log(res.data);
            setData(res.data.tests);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            // console.error(err);
          });
      } else if (userRole === 1) {
        setTimeout(() => {
          setLoading(true);
          testService
            .getTests(userId, null)
            .then((res) => {
              const { iv, encryptedData } = res.tests;
              const decryptedText = decrypt(encryptedData, iv);
              const parsedData = JSON.parse(decryptedText);
              // console.log(parsedData);
              setData(parsedData);
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        }, 500);
      }
    }
  }, [token]);

  useEffect(() => {
    (async () => {
      let a = await authService.getUser(token);
      const userId = jwtDecode(token).id;
      let res = await iparhaiServer.post("api/test/getAvailableMinutes", {
        userId,
      });
      setAvailableMinutes(res.data.availableMinutes);
    })();
  }, [openModal]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const getAllTest = async () => {
    testService
      .getAllTests(userId)
      .then((res) => {
        // console.log(res.data);
        setData(res.data.tests);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.error(err);
      });
  };

  const itemsPerPage = 3; // Define number of items per page
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const userId = localStorage.getItem("userId");

  const handleOpenTestModal = (content) => {
    setModalContent(content);
    setOpenTestModal(true);
  };
  const handleCloseTestModal = () => {
    setOpenTestModal(false);
  };
  const validate = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^[a-zA-Z0-9]*$/;
    if (!passwordRegex.test(testCode)) {
      settestCodeerr([true, "Test code cannot contain special characters."]);
      return false;
    }

    return true;
  };

  const handleTestCode = (e) => {
    setTestCode(e.target.value);
  };

  return (
    <>
      {/* <NetworkStatus/> */}
      <Box
        height="100vh"
        sx={{
          position: "relative",
          bgcolor: "#F8F8F8",
          // mt: "1rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={0} sm={3}>
            <SideBar />
          </Grid>
          <Grid item xs={10} sm={9}>
            {jwtDecode(token).role === 2 ? (
              <>
                {" "}
                <div className="relative">
                  <span className="absolute left-20 top-6 bg-blue-100 px-4 py-1 rounded-lg text-2xl font-medium">
                    Available Test Minutes:{" "}
                    <span className="text-[#004E8F] text-3xl">
                      {availableMinutes}
                    </span>
                    <IconButton
                      size="small"
                      color="info"
                      onClick={handleOpeninfo}
                    >
                      <InfoIcon />
                    </IconButton>
                    <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="info"
                        sx={{ width: "80%" }}
                      >
                        Test Minutes is assigned by Proctorparhai admin i.e
                        Duration of test in minutes * total number of candidates
                        shouldn't be exceed then available minutes
                      </Alert>
                    </Snackbar>
                  </span>
                  <Button
                    onClick={openModal}
                    startIcon={<AddIcon />}
                    variant="contained"
                    sx={{
                      marginTop: "1rem",
                      marginRight: "1rem",
                      marginLeft: {
                        xs: "17vw", //  between 0px and 600px
                        sm: "35vw", //  between 600px and 960px
                        md: "50vw", //  between 960px and 1280px
                        lg: "50vw", // between 1280px and 1920px
                        xl: "50vw", //  above 1920px
                      },
                      bgcolor: "#004E8F",
                      color: "white",
                      fontSize: {
                        xs: "2.75vw", //  between 0px and 600px
                        sm: "2vw", //  between 600px and 960px
                        md: "1.25vw", //  between 960px and 1280px
                        lg: "1.25vw", // between 1280px and 1920px
                        xl: "1.25vw", //  above 1920px
                      },
                    }}
                  >
                    Create New Assessment
                  </Button>
                </div>
                <Dialog
                  open={isModalOpen}
                  // onClose={closeModal}
                  sx={{ "& .MuiDialog-paper": { maxWidth: "1100px" , width:'1100px' }}} // Set the desired width here
                >
                  <DialogContent>
                    <Createassessment availableMinutes={availableMinutes} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closeModal} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ) : jwtDecode(token).role === 4 ? (
              <>
                {" "}
                <div className="relative">
                  <span className="absolute left-20 top-6 bg-blue-100 px-4 py-1 rounded-lg text-2xl font-medium">
                    Available Test Minutes:{" "}
                    <span className="text-[#004E8F] text-3xl">
                      {availableMinutes}
                    </span>
                  </span>
                  <Button
                    onClick={openModal}
                    startIcon={<AddIcon />}
                    variant="contained"
                    sx={{
                      marginTop: "1rem",
                      marginRight: "1rem",
                      marginLeft: {
                        xs: "17vw", //  between 0px and 600px
                        sm: "35vw", //  between 600px and 960px
                        md: "50vw", //  between 960px and 1280px
                        lg: "50vw", // between 1280px and 1920px
                        xl: "50vw", //  above 1920px
                      },
                      bgcolor: "#004E8F",
                      color: "white",
                      fontSize: {
                        xs: "2.75vw", //  between 0px and 600px
                        sm: "2vw", //  between 600px and 960px
                        md: "1.25vw", //  between 960px and 1280px
                        lg: "1.25vw", // between 1280px and 1920px
                        xl: "1.25vw", //  above 1920px
                      },
                    }}
                  >
                    Create New Assessment
                  </Button>
                </div>
                <Dialog
                  className="w-[2000px] mx-auto"
                  open={isModalOpen}
                  onClose={closeModal}
                >
                  <DialogContent>
                    <Createassessment availableMinutes={availableMinutes} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closeModal} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ) : jwtDecode(token).role === 1 ? (
              <>
                {testCodeerr[0] && (
                  <Alert
                    sx={{
                      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
                      ml: {
                        sm: "6rem", //  between 600px and 960px
                        md: "auto", // between 1280px and 1920px
                      },
                      mr: "auto",
                      padding: "0.25rem",
                      width: "fit-content",
                      mt: {
                        xs: "3.25rem", //  between 0px and 600px
                        sm: "1rem",
                      },
                    }}
                    severity="error"
                  >
                    {testCodeerr[1]}
                  </Alert>
                )}
                <Box
                  sx={{
                    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
                    ml: {
                      sm: "6rem", //  between 600px and 960px
                      md: "auto", // between 1280px and 1920px
                    },
                    mr: "auto",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "1rem",
                    padding: "0.25rem",
                    width: {
                      sm: "80%",
                      // between 1280px and 1920px
                    },
                    color: "black",
                    bgcolor: "white",
                    borderRadius: "5px",
                    mt: {
                      xs: testCodeerr[0] ? "1rem" : "3.25rem", //  between 0px and 600px
                      sm: "1rem",
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Enter Test Code To Join:
                  </Typography>
                  <FormControl variant="standard">
                    <Input
                      sx={{
                        width: {
                          xs: "100%", //  between 0px and 600px
                          sm: "5rem", //  between 600px and 960px
                          md: "100%", //  between 960px and 1280px
                          lg: "22rem", // between 1280px and 1920px
                          xl: "35rem", //  above 1920px
                        },
                      }}
                      value={testCode}
                      onChange={handleTestCode}
                      placeholder="ssxHu22"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          register(
                            jwtDecode(token).id,
                            testCode,
                            jwtDecode(token).company
                          );
                        }
                      }}
                      required
                    />
                  </FormControl>
                  <Button
                    onClick={() =>
                      register(
                        jwtDecode(token).id,
                        testCode,
                        jwtDecode(token).company
                      )
                    }
                    variant="contained"
                    sx={{
                      bgcolor: "#004ERF",
                      color: "white",
                      fontSize: {
                        xs: "0.4rem", //  between 0px and 600px
                        md: "1rem",
                      },
                    }}
                  >
                    Join Test
                  </Button>
                </Box>
              </>
            ) : (
              <></>
            )}
            {jwtDecode(token).role === 3 ? (
              <>
                <Box
                  sx={{
                    ml: {
                      xs: "2rem", //  above 1920px
                      sm: "0", //  above 1920px
                      md: data
                        ? data.length > 1
                          ? "1.5rem"
                          : "4.5rem"
                        : "1.5rem", //  above 1920px
                      lg: data
                        ? data.length > 1
                          ? "2.25rem"
                          : "5.75rem"
                        : "2.25", //  above 1920px
                      xl: data
                        ? data.length > 1
                          ? "4rem"
                          : "7.25rem"
                        : "4rem", //  above 1920px
                    },
                    padding: "1rem 0",
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      md: "flex-start",
                    },
                    flexWrap: "wrap",
                    gap: "0.75rem",
                  }}
                >
                  <Adminsignup />
                </Box>
              </>
            ) : (
              <>
                {" "}
                <Box
                  sx={{
                    ml: {
                      xs: "2rem", //  above 1920px
                      sm: "0", //  above 1920px
                      md: data
                        ? data.length > 1
                          ? "1.5rem"
                          : "4.5rem"
                        : "1.5rem", //  above 1920px
                      lg: data
                        ? data.length > 1
                          ? "2.25rem"
                          : "5.75rem"
                        : "2.25", //  above 1920px
                      xl: data
                        ? data.length > 1
                          ? "4rem"
                          : "7.25rem"
                        : "4rem", //  above 1920px
                    },
                    padding: "1rem 0",
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      md: "flex-start",
                    },
                    flexWrap: "wrap",
                    gap: "0.75rem",
                  }}
                >
                  {!loading ? (
                    <>
                      {data &&
                        data.slice(startIndex, endIndex).map((test) => {
                          return (
                            <AssessCard
                              availableMinutes={availableMinutes}
                              getAllTest={getAllTest}
                              key={test.id}
                              test={test}
                            />
                          );
                        })}
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100vh",
                          width: "100%",
                        }}
                      >
                        <Circles
                          height="80"
                          width="80"
                          color="#004e8f"
                          ariaLabel="circles-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </div>
                    </>
                  )}
                </Box>
                {data && data.length > itemsPerPage && (
                  <Pagination
                    count={Math.ceil(data.length / itemsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    sx={{
                      mt: "2rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                )}
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={openTestModal}
                  onClose={handleCloseTestModal}
                  closeAfterTransition
                >
                  <Fade in={openTestModal}>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                      }}
                    >
                      <Typography
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                      ></Typography>
                      <Typography
                        id="transition-modal-description"
                        sx={{ mt: 2 }}
                      >
                        {modalContent}
                      </Typography>
                    </Box>
                  </Fade>
                </Modal>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Assessments;
