import axios from "axios";

export const iparhaiServer = axios.create();
iparhaiServer.defaults.baseURL = "https://api.proctorparhai.com/";
// iparhaiServer.defaults.baseURL = "https://api.techparhai.com/";
// export  const iparhaiServer = axios.create();

// iparhaiServer.defaults.baseURL = "http://localhost:8080/";

// 
