import React from 'react';
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  Button
} from '@mui/material';
import { Bars } from 'react-loader-spinner';
import QuestionAccordion from '../Question accordion/QuestionAccordion';

const ViewReportModal = ({
  open,
  onClose,
  pdfDataUrl,
  setPdfDataUrl, setViewReportModal,
  questionData,
  viewQuestionLoading,
  handleDownloadDocx,
  viewReport,
  test,
  userDetails,
  gradelevel
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            height: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
        >
          <div className="h-full px-2 py-12">
            {pdfDataUrl ? (
              <iframe
                className="w-full h-full"
                src={pdfDataUrl}
                title="PDF Report"
              />
            ) : (
              <>
                <div className="p-6 overflow-y-scroll h-[80%]">
                  {questionData.length > 0 &&
                    questionData.map((e, id) => {
                      return (
                        <QuestionAccordion
                          key={id}
                          no={id}
                          questionID={e.id}
                          question={e.question}
                          answer={e.answer}
                          autoGradingMarks={e.autoGradingMarks}
                          totalMarks={e.marks}
                          gradelevel={gradelevel}
                        />
                      );
                    })}
                  {viewQuestionLoading && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '540px',
                        }}
                      >
                        <Bars
                          height="80"
                          width="80"
                          color="#004e8f"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            <div className="my-4 flex gap-10 justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2">
              <Button
                variant="contained"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
              {!pdfDataUrl && (
                <>
                  <Button variant="contained" onClick={handleDownloadDocx}>
                    Download
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => viewReport(userDetails.id,test,setPdfDataUrl,setViewReportModal)}
                  >
                    View AI Report
                  </Button>
                </>
              )}
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ViewReportModal;
