import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box } from "@mui/material";
import { useEffect, useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import testService from "../../store/services/test.service";
import { useDispatch, useSelector } from "react-redux";
import {
  setProctorCount,
  setnoface,
  setTrueCountt,
  setSimilarityscore,
  setobjResponses,
  setnoisecount,
  setLeftCount,
  setCenterCount,
  setRightCount,
  setupCount,
  setdownCount,
} from "../../store/actions/proctorcount";
import proctor from "../../store/actions/proctor";
import { decrypt } from "../../components/encryptfunction/decrypt";

function ProctorTest({ inputHeight, inputWidth }) {
  const webcamRef = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [screenSize, setScreenSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [switchCount, setSwitchCount] = useState(0);
  const [frames, setFrames] = useState([]);
  const [alert , setAlert] = useState({show:false, message:''})
  const [totalSimilarityScore, setTotalSimilarityScore] = useState(0);
  const [zeroSimilarityCount, setZeroSimilarityCount] = useState(0);
  const [leftCount, setLeftCountState] = useState(0);
  const [centerCount, setCenterCountState] = useState(0);
  const aiproctor = useSelector((state) => state.tests.aiproctor);
  const [isRecording, setIsRecording] = useState(false);
  const recognitionRef = useRef(null);
  const SpeechRecognition =
    window.webkitSpeechRecognition || window.SpeechRecognition; // Fallback for compatibility

  // Helper function to convert WebP to JPEG Base64
  const convertWebPToJpegBase64 = useCallback((webpBase64) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = webpBase64;

    return new Promise((resolve, reject) => {
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL("image/jpeg"));
      };
      img.onerror = (error) => reject(error);
    });
  }, []);

  const checkWebcamAvailability = useCallback(async () => {
    try {
      // First, try to get the video stream
      const videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoStream.getTracks().forEach((track) => track.stop());
    } catch (videoError) {
      // console.log(videoError);
      if (aiproctor) {
        setAlert({ show: true, message: "Webcam is not available." });
        setLoading(false);
        return; 
      }
    }
  
    try {
      // Then, try to get the audio stream
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioStream.getTracks().forEach((track) => track.stop());
      setLoading(false);
    } catch (audioError) {
      // console.log(audioError);
      if (aiproctor) {
        setAlert({ show: true, message: "Audio is not available." });
        setLoading(false);
      }
    }
  }, [aiproctor]);
  

  // Function to send frame to server
  const sendFrameToServer = useCallback(async () => {
    try {
      const imageSrc = webcamRef.current.getScreenshot();
      const jpegBase64 = await convertWebPToJpegBase64(imageSrc);
      const imageData = jpegBase64.replace(/^data:image\/jpeg;base64,/, "");

      setFrames((prevFrames) => [...prevFrames, jpegBase64]);

       await testService.proctor(imageData);

       await testService.awsobjectdetect(imageData);
    } catch (error) {
      // console.error("Error sending frame to server:", error);
    }
  }, [convertWebPToJpegBase64, dispatch]);

  const gazeProctorFrame = useCallback(async () => {
    try {
      const imageSrc = webcamRef.current.getScreenshot();
       await testService.gazeproctor(imageSrc, screenSize);
    } catch (error) {
      // console.error("Error in gaze proctor:", error);
    }
  }, [screenSize]);

  useEffect(() => {
    checkWebcamAvailability();
  }, [checkWebcamAvailability]);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(setProctorCount(frames.length));
    dispatch(setSimilarityscore(totalSimilarityScore));
  }, [
    frames.length,
    zeroSimilarityCount,
    totalSimilarityScore,
    centerCount,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(proctor.setSwitches(switchCount));

    const handleWindowBlur = () => {
      // console.log("switch");
      setSwitchCount((prev) => prev + 1);
    };

    document.addEventListener("visibilitychange", handleWindowBlur);
    window.addEventListener("blur", handleWindowBlur);
    return () => {
      document.removeEventListener("visibilitychange", handleWindowBlur);
      window.removeEventListener("blur", handleWindowBlur);
    };
  }, [switchCount]);

  useEffect(() => {
    if (aiproctor) {
      const sendFrameInterval = setInterval(sendFrameToServer, 15000);
      const gazeProctorInterval = setInterval(gazeProctorFrame, 5000);

      return () => {
        clearInterval(sendFrameInterval);
        clearInterval(gazeProctorInterval);
      };
    }
  }, [aiproctor, sendFrameToServer, gazeProctorFrame]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 70,
          }}
        >
          <FontAwesomeIcon icon={faSpinner} spin />
        </Box>
      ) : (
        <>
          {aiproctor ? (
            <>
              <Webcam
                height={inputHeight}
                id="video-element"
                width={inputWidth}
                ref={webcamRef}
              />
            </>
          ) : null}
        </>
      )}
      {alert.show && (
        <Alert className="fixed top-16 z-40 left-[50%] transform translate-x-[-50%] " variant="filled" severity="error" onClose={()=>setAlert({show:false, message:''})} >
          {alert.message}
        </Alert>
      )}
    </>
  );
}

export default ProctorTest;
